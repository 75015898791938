import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
//import * as $ from 'jquery';

import {UserService}  from '../../../core/service/user.service';

import { UserModel } from '../../../core/models/user.model';

@Component({
  selector: 'app-user',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.css']
})
export class UserListComponent implements OnInit {

  userListSub: Subscription;
  userList: UserModel[];

  loading: boolean = true;
  error: boolean = false;

  total: number = 0;
  page: number = 1;
  perPage: number = 10;
  pagesToShow: number = 4;
  
  sort: string = 'ASC';
  order: string = 'id';

  constructor(
    private http: HttpClient, 
    private toastr: ToastrService, 
    private api: UserService
  ) {}

  ngOnInit() {
     this._getUserList();
     this._setSortOrder();
  }

  ngOnDestroy() {
    this.userListSub.unsubscribe();
  }

  private _getUserList(): void {
    this.userListSub = this.api.fetchGrid$({ page: this.page, perPage: this.perPage, order: this.order, sort: this.sort })
      .subscribe(res => {
          this.total = res.meta.total;
          this.userList = res.data;
          this.loading = false;
      }, (err: HttpErrorResponse) => {
          this.loading = false;
          this.error = true;
          this.toastr.error(`Error authenticating: ${err.error}`);
      });
  }

  private _setSortOrder(): void {
    // $(document).on('click', 'table > thead > tr > th', (e) => {
    $('table > thead > tr > th').on('click', (e) => {
      let el = $(e.target).closest('th');
      let field = el.data('field');
      let span = $('span:first', el);
      let spanText = span.text();
      let spanWidth = span.css('width');

      if (field === 'actions' || field === 'false') {
        return;
      }
  
      this.sort = (this.sort === 'DESC') ? 'ASC' : 'DESC';
      this.order = field;
  
      let icon = (this.sort === 'DESC') ? 'la-arrow-down' : 'la-arrow-up';
  
      // remove all direction arrows
      $('.m-datatable__table .m-datatable__head tr').find('i').remove();
  
      span.text('').append(`${spanText}<i class="la ${icon}"></i>`);
  
      this._getUserList();
    });
  }

  goToPage(n: number): void {
    this.page = n;
    this._getUserList();
  }

  goToPerPage(n: number): void {
    this.perPage = n;
    this._getUserList();
  }

  onNext(): void {
    this.page++;
    this._getUserList();
  }

  onPrev(): void {
    this.page--;
    this._getUserList();
  }

  onFirst(): void {
    this.page = 1;
    this._getUserList();
  }

  onLast(): void {
    this._getUserList();
  }
  
}
