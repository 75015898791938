import * as $ from "jquery";
import * as Cookies from "js-cookie";

export class Helpers {

  static breakpoints: any = {
    sm: 544, // Small screen / phone           
    md: 768, // Medium screen / tablet            
    lg: 1024, // Large screen / desktop        
    xl: 1200 // Extra large screen / wide desktop
  };

  static isMobileDevice() {
      return (this.getViewPort().width < this.getBreakpoint('lg') ? true : false);
  }

  static isDesktopDevice() {
    return this.isMobileDevice() ? false : true;
  }

  static getViewPort() {
    let a = (!('innerWidth' in window)) ? 'client' : 'inner';
    let e = (!('innerWidth' in window)) ? document.documentElement || document.body : window;
    return {
        width: e[a + 'Width'],
        height: e[a + 'Height']
    };
  }

  static isInResponsiveRange(mode) {
    let breakpoint = this.getViewPort().width;
    if (mode == 'general') {
        return true;
    } else if (mode == 'desktop' && breakpoint >= (this.getBreakpoint('lg') + 1)) {
        return true;
    } else if (mode == 'tablet' && (breakpoint >= (this.getBreakpoint('md') + 1) && breakpoint < this.getBreakpoint('lg'))) {
        return true;
    } else if (mode == 'mobile' && breakpoint <= this.getBreakpoint('md')) {
        return true;
    } else if (mode == 'desktop-and-tablet' && breakpoint >= (this.getBreakpoint('md') + 1)) {
        return true;
    } else if (mode == 'tablet-and-mobile' && breakpoint <= this.getBreakpoint('lg')) {
        return true;
    } else if (mode == 'minimal-desktop-and-below' && breakpoint <= this.getBreakpoint('xl')) {
        return true;
    }
    return false;
  }

  static getUniqueID(prefix) {
    return prefix + Math.floor(Math.random() * (new Date()).getTime());
  }

  static getBreakpoint(mode) {
    return Helpers.breakpoints[mode];
  }

  static loadStyles(tag, src) {
    if (Array.isArray(src)) {
      $.each(src, function(k, s) {
          $(tag).append($('<link/>').attr('href', s).attr('rel', 'stylesheet').attr('type', 'text/css'));
      });
    } else {
      $(tag).append($('<link/>').attr('href', src).attr('rel', 'stylesheet').attr('type', 'text/css'));
    }
  }

  static setLoading(enable) {
    let body = $('body');
    if (enable) {
      $(body).addClass('m-page--loading-non-block')
    } else {
      $(body).removeClass('m-page--loading-non-block')
    }
  }

  static bodyClass(strClass) {
    $('body').attr('class', strClass);
  }

  static addBodyClass(strClass) {
    $('body').addClass(strClass);
  }

  static sidebarInit() {
    let mode = (this.isDesktopDevice()) ? 'desktop' : 'mobile';
    let state = Cookies.get('sidebar_toggle_state') ? Cookies.get('sidebar_toggle_state') : 'on';
    this.toggleSidebar(state, mode);
  }

  static toggleSidebar(toggleState: string = 'on', mode: string = 'desktop') {

    console.log('init-state', toggleState);
    console.log('init-mode', mode);

    let body = document.getElementsByTagName("body")[0];
    let aside = document.getElementById('m_aside_left');

    if (mode === 'desktop') {
      if (toggleState === 'any') {
        if (body.classList.contains('m-brand--minimize')){
          body.classList.remove('m-brand--minimize', 'm-aside-left--minimize');
          toggleState = 'on';
        } else {
          body.classList.add('m-brand--minimize', 'm-aside-left--minimize');
          toggleState = 'off';
        }
      } else {
        if (toggleState === 'on'){
          body.classList.remove('m-brand--minimize', 'm-aside-left--minimize');
        } else {
          body.classList.add('m-brand--minimize', 'm-aside-left--minimize');
        }
      }
    } else if (mode === 'mobile') {

      if (toggleState === 'any') {
        if (body.classList.contains('m-aside-left--on')){
          body.classList.remove('m-aside-left--on');
          toggleState = 'off';
        } else {
          body.classList.add('m-aside-left--on');
          toggleState = 'on';
        }
        if (aside.classList.contains('m-aside-left--on')){
          aside.classList.remove('m-aside-left--on');
        } else {
          aside.classList.add('m-aside-left--on');
        }
      } else {
        if (toggleState === 'on') {
          body.classList.add('m-aside-left--on');
          aside.classList.add('m-aside-left--on');
        } else {
          body.classList.remove('m-aside-left--on');
          aside.classList.remove('m-aside-left--on');
        }
      }

    }
    
    Cookies.set('sidebar_toggle_state', toggleState);
  }

}