import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Route guards
import { AuthGuard } from '../auth/auth.guard';
import { AdminGuard } from '../auth/admin.guard';
import { RoleGuard } from '../auth/role.guard';

// Components
import { NotfoundComponent } from './notfound/notfound.component';

const routes: Routes = [
  {
    path: 'member',
    loadChildren: '../member/member.module#MemberModule',
    canActivate: [
      AuthGuard,
      RoleGuard
    ],
    data: { 
      expects: 'ROLE_MEMBER'
    }
  }, {
    path: 'admin',
    loadChildren: '../admin/admin.module#AdminModule',
    canActivate: [
      AuthGuard,
      AdminGuard
    ]
  }, { 
    path: '404', 
    component: NotfoundComponent 
  }, { 
    path: '**',  
    component: NotfoundComponent 
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class CoreRoutingModule { }
