import { Component, OnInit } from '@angular/core';
import { adminMenu } from '../core/models/menus.model';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {

  menu: any[] = adminMenu;

  constructor() { }

  ngOnInit() {

  }

}
