import { Component, OnInit } from '@angular/core';
import { memberMenu } from '../core/models/menus.model';

@Component({
  selector: 'app-member',
  templateUrl: './member.component.html',
  styleUrls: ['./member.component.css']
})
export class MemberComponent implements OnInit {

  menu: any[] = memberMenu;

  constructor() { }

  ngOnInit() {

  }

}
