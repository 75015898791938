import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { RoleGuard } from '../auth/role.guard';

import { MemberComponent } from './member.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { TrackerComponent } from './tracker/tracker.component';

const routes: Routes = [{
    path: 'member',
    component: MemberComponent,
    children: [{ 
      path: '', 
      component: DashboardComponent,
      canActivate: [
        RoleGuard
      ],
      data: { 
        expects: 'ROLE_MEMBER'
      } 
    }, { 
      path: 'tracker', 
      component: TrackerComponent,
      canActivate: [
        RoleGuard
      ],
      data: { 
        expects: 'ROLE_MEMBER'
      } 
    }]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MemberRoutingModule { }
