import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

@Injectable()
export class AuthGuard implements CanActivate {
  
  constructor(
    private router : Router
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot, 
    state: RouterStateSnapshot): boolean {
      if (localStorage.getItem('userToken')) {
        return true;
      } else {
        this.router.navigate(['/login']);
        return false;
      }
  }
}
