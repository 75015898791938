import { Injectable } from '@angular/core';
import { Observable, fromEvent, merge, of } from 'rxjs';
import { mapTo } from 'rxjs/operators';

@Injectable()
export class NetworkService {

  online$: Observable<boolean>;

  constructor() {
    
    this.online$ = merge(
      of(navigator.onLine),
      fromEvent(window, 'online').pipe(mapTo(true)),
      fromEvent(window, 'offline').pipe(mapTo(false))
    );

    this.online$.subscribe(e => {
      if (e) {
        console.log('System is online.');
      } else {
        console.log('System is offline.');
      }
    });

   }
}
