import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { BehaviorSubject, Subscription, of, timer } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

@Injectable()
export class AuthService {
  
  accessToken: string;
  userProfile: any;
  isAdmin: boolean;
  loggedIn: boolean;
  loggedIn$ = new BehaviorSubject<boolean>(this.loggedIn);
  loggingIn: boolean;
  refreshSub: Subscription;

  constructor(
    private http: HttpClient, 
    private router: Router
  ) { 
    if (this.tokenValid) {
      this.renewToken(localStorage.getItem('userToken'));
    }
  }

  setLoggedIn(value: boolean) {
    this.loggedIn$.next(value);
    this.loggedIn = value;
  }

  handleAuth(authResult) {
    if (authResult && authResult.accessToken) {
      this._getProfile(authResult.accessToken);
    }
  }

  private _decodeToken(accessToken) {
    let jwtData = accessToken.split('.')[1]
    let decodedJwtJsonData = window.atob(jwtData)
    return JSON.parse(decodedJwtJsonData)
  }

  private _getProfile(accessToken) {
    this.loggingIn = true;
    this.accessToken = accessToken;
    localStorage.setItem('userToken', accessToken);

    let decodedJwtData = this._decodeToken(accessToken)

    if (decodedJwtData.data) {
      this._setSession(decodedJwtData, decodedJwtData.data);
    }
  }

  private _setSession(authResult, profile?) {
    const expiresAt = JSON.stringify((authResult.exp * 1000)); // expiresIn
    localStorage.setItem('expires_at', expiresAt);
    if (profile) {
      this.userProfile = profile;
      this.isAdmin = this._checkAdmin(profile);
    }
    this.setLoggedIn(true);
    this.loggingIn = false;
    this.scheduleRenewal();
    console.log('profile', profile)
  }

  private _checkAdmin(profile) {
    const roles = profile['roles'] || [];
    return roles.indexOf('ROLE_ADMIN') > -1;
  }

  private _redirect() {
    this.router.navigate(['/']);
    this._clearRedirect();
  }

  private _clearRedirect() {
    localStorage.removeItem('authRedirect');
  }

  logout(noRedirect?: boolean) {
    localStorage.removeItem('expires_at');
    this._clearRedirect();
    this.accessToken = undefined;
    this.userProfile = undefined;
    this.isAdmin = undefined;
    this.setLoggedIn(false);
    this.unscheduleRenewal();
    if (noRedirect !== true) {
      this.router.navigate(['/']);
    }
  }

  get tokenValid(): boolean {
    const expiresAt = JSON.parse(localStorage.getItem('expires_at'));
    return Date.now() < expiresAt;
  }

  renewToken(authToken) {
    if (authToken) {
      this._getProfile(authToken);
    } else {
      console.warn('Failed to renew access token.');
      this.logout(true);
    }
  }

  scheduleRenewal() {
    if (!this.tokenValid) { return; }

    this.unscheduleRenewal();

    const expiresAt = JSON.parse(localStorage.getItem('expires_at'));
    const expiresIn$ = of(expiresAt).pipe(
      mergeMap(
        expires => {
          const now = Date.now();
          return timer(Math.max(1, expires - now));
        }
      )
    );

    this.refreshSub = expiresIn$.subscribe(() => {
      console.warn('scheduleRenewal')
      //this.renewToken(authToken);
      //this.scheduleRenewal(authToken);
    });
  }

  unscheduleRenewal() {
    if (this.refreshSub) {
      this.refreshSub.unsubscribe();
    }
  }

}
