import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { FrontRoutingModule } from './front-routing.module';

import { FrontComponent } from './front.component';
import { UserComponent } from './user/user.component';
import { SignInComponent } from './user/sign-in/sign-in.component';
import { SignUpComponent } from './user/sign-up/sign-up.component';

@NgModule({
  imports: [
    CommonModule,
    FrontRoutingModule,
    FormsModule,
  ],
  declarations: [
    FrontComponent,
    UserComponent,
    SignUpComponent,
    SignInComponent
  ]
})
export class FrontModule { }
