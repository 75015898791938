import { Directive, ElementRef, Renderer2, Input } from '@angular/core';

@Directive({
  selector: '[requiredField]'
})
export class RequiredFieldDirective {

  private el: HTMLElement;

  @Input('fieldValidity') field;

  constructor(el: ElementRef) { 
    this.el = el.nativeElement;
  }

  // ngDoCheck(value: string) {
  //   if (field.valid || field.pristine) { 
  //     this.el.classList.remove('has-error');
  //   } else {
  //     this.el.classList.add('has-error');
  //   }
  // }

  // private _onValidityChange(value: string) {
  //   //TODO test field.valid || field.pristine
  //   if (?) { 
  //     this.el.classList.remove('has-error');
  //   } else {
  //     this.el.classList.add('has-error');
  //   }
  // }

}
