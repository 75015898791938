import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../auth/auth.service';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit {

  constructor(
    private authService: AuthService, 
    private router: Router, 
  ) { }

  ngOnInit() {
    if (this.authService.loggedIn) {
      this.router.navigate(['/member'])
    }
  }

}
