export const states: any[] = [{
  state: "Alabama",
  value: "Alabama"
}, {
  state: "Alaska",
  value: "Alaska"
}, {
  state: "Arizona",
  value: "Arizona"
}, {
  state: "Arkansas",
  value: "Arkansas"
}, {
  state: "California",
  value: "California"
}, {
  state: "Colorado",
  value: "Colorado"
}, {
  state: "Connecticut",
  value: "Connecticut"
}, {
  state: "Delaware",
  value: "Delaware"
}, {
  state: "District of Columbia",
  value: "District of Columbia"
}, {
  state: "Florida",
  value: "Florida"
}, {
  state: "Georgia",
  value: "Georgia"
}, {
  state: "Hawaii",
  value: "Hawaii"
}, {
  state: "Idaho",
  value: "Idaho"
}, {
  state: "Illinois",
  value: "Illinois"
}, {
  state: "Indiana",
  value: "Indiana"
}, {
  state: "Iowa",
  value: "Iowa"
}, {
  state: "Kansas",
  value: "Kansas"
}, {
  state: "Kentucky",
  value: "Kentucky"
}, {
  state: "Louisiana",
  value: "Louisiana"
}, {
  state: "Maine",
  value: "Maine"
}, {
  state: "Maryland",
  value: "Maryland"
}, {
  state: "Massachusetts",
  value: "Massachusetts"
}, {
  state: "Michigan",
  value: "Michigan"
}, {
  state: "Minnesota",
  value: "Minnesota"
}, {
  state: "Mississippi",
  value: "Mississippi"
}, {
  state: "Missouri",
  value: "Missouri"
}, {
  state: "Montana",
  value: "Montana"
}, {
  state: "Nebraska",
  value: "Nebraska"
}, {
  state: "Nevada",
  value: "Nevada"
}, {
  state: "New Hampshire",
  value: "New Hampshire"
}, {
  state: "New Jersey",
  value: "New Jersey"
}, {
  state: "New Mexico",
  value: "New Mexico"
}, {
  state: "New York",
  value: "New York"
}, {
  state: "North Carolina",
  value: "North Carolina"
}, {
  state: "North Dakota",
  value: "North Dakota"
}, {
  state: "Ohio",
  value: "Ohio"
}, {
  state: "Oklahoma",
  value: "Oklahoma"
}, {
  state: "Oregon",
  value: "Oregon"
}, {
  state: "Other",
  value: "Other"
}, {
  state: "Pennsylvania",
  value: "Pennsylvania"
}, {
  state: "Rhode Island",
  value: "Rhode Island"
}, {
  state: "South Carolina",
  value: "South Carolina"
}, {
  state: "South Dakota",
  value: "South Dakota"
}, {
  state: "Tennessee",
  value: "Tennessee"
}, {
  state: "Texas",
  value: "Texas"
}, {
  state: "Utah",
  value: "Utah"
}, {
  state: "Vermont",
  value: "Vermont"
}, {
  state: "Virginia",
  value: "Virginia"
}, {
  state: "Washington",
  value: "Washington"
}, {
  state: "West Virginia",
  value: "West Virginia"
}, {
  state: "Wisconsin",
  value: "Wisconsin"
}, {
  state: "Wyoming",
  value: "Wyoming"
}]

export const countries: any[] = [{
  id: 1,
  value: "Afghanistan"
}, {
  id: 2,
  value: "Aland Islands"
}, {
  id: 3,
  value: "Albania"
}, {
  id: 4,
  value: "Algeria"
}, {
  id: 5,
  value: "American Samoa"
}, {
  id: 6,
  value: "AndorrA"
}, {
  id: 7,
  value: "Angola"
}, {
  id: 8,
  value: "Anguilla"
}, {
  id: 9,
  value: "Antarctica"
}, {
  id: 10,
  value: "Antigua and Barbuda"
}, {
  id: 11,
  value: "Argentina"
}, {
  id: 12,
  value: "Armenia"
}, {
  id: 13,
  value: "Aruba"
}, {
  id: 14,
  value: "Australia"
}, {
  id: 15,
  value: "Austria"
}, {
  id: 16,
  value: "Azerbaijan"
}, {
  id: 17,
  value: "Bahamas"
}, {
  id: 18,
  value: "Bahrain"
}, {
  id: 19,
  value: "Bangladesh"
}, {
  id: 20,
  value: "Barbados"
}, {
  id: 21,
  value: "Belarus"
}, {
  id: 22,
  value: "Belgium"
}, {
  id: 23,
  value: "Belize"
}, {
  id: 24,
  value: "Benin"
}, {
  id: 25,
  value: "Bermuda"
}, {
  id: 26,
  value: "Bhutan"
}, {
  id: 27,
  value: "Bolivia"
}, {
  id: 28,
  value: "Bosnia and Herzegovina"
}, {
  id: 29,
  value: "Botswana"
}, {
  id: 30,
  value: "Bouvet Island"
}, {
  id: 31,
  value: "Brazil"
}, {
  id: 32,
  value: "British Indian Ocean Territory"
}, {
  id: 33,
  value: "Brunei Darussalam"
}, {
  id: 34,
  value: "Bulgaria"
}, {
  id: 35,
  value: "Burkina Faso"
}, {
  id: 36,
  value: "Burundi"
}, {
  id: 37,
  value: "Cambodia"
}, {
  id: 38,
  value: "Cameroon"
}, {
  id: 39,
  value: "Canada"
}, {
  id: 40,
  value: "Cape Verde"
}, {
  id: 41,
  value: "Cayman Islands"
}, {
  id: 42,
  value: "Central African Republic"
}, {
  id: 43,
  value: "Chad"
}, {
  id: 44,
  value: "Chile"
}, {
  id: 45,
  value: "China"
}, {
  id: 46,
  value: "Christmas Island"
}, {
  id: 47,
  value: "Cocos (Keeling) Islands"
}, {
  id: 48,
  value: "Colombia"
}, {
  id: 49,
  value: "Comoros"
}, {
  id: 50,
  value: "Congo"
}, {
  id: 51,
  value: "Congo, The Democratic Republic of the"
}, {
  id: 52,
  value: "Cook Islands"
}, {
  id: 53,
  value: "Costa Rica"
}, {
  id: 54,
  value: "Cote D Ivoire"
}, {
  id: 55,
  value: "Croatia"
}, {
  id: 56,
  value: "Cuba"
}, {
  id: 57,
  value: "Cyprus"
}, {
  id: 58,
  value: "Czech Republic"
}, {
  id: 59,
  value: "Denmark"
}, {
  id: 60,
  value: "Djibouti"
}, {
  id: 61,
  value: "Dominica"
}, {
  id: 62,
  value: "Dominican Republic"
}, {
  id: 63,
  value: "Ecuador"
}, {
  id: 64,
  value: "Egypt"
}, {
  id: 65,
  value: "El Salvador"
}, {
  id: 66,
  value: "Equatorial Guinea"
}, {
  id: 67,
  value: "Eritrea"
}, {
  id: 68,
  value: "Estonia"
}, {
  id: 69,
  value: "Ethiopia"
}, {
  id: 70,
  value: "Falkland Islands (Malvinas)"
}, {
  id: 71,
  value: "Faroe Islands"
}, {
  id: 72,
  value: "Fiji"
}, {
  id: 73,
  value: "Finland"
}, {
  id: 74,
  value: "France"
}, {
  id: 75,
  value: "French Guiana"
}, {
  id: 76,
  value: "French Polynesia"
}, {
  id: 77,
  value: "French Southern Territories"
}, {
  id: 78,
  value: "Gabon"
}, {
  id: 79,
  value: "Gambia"
}, {
  id: 80,
  value: "Georgia"
}, {
  id: 81,
  value: "Germany"
}, {
  id: 82,
  value: "Ghana"
}, {
  id: 83,
  value: "Gibraltar"
}, {
  id: 84,
  value: "Greece"
}, {
  id: 85,
  value: "Greenland"
}, {
  id: 86,
  value: "Grenada"
}, {
  id: 87,
  value: "Guadeloupe"
}, {
  id: 88,
  value: "Guam"
}, {
  id: 89,
  value: "Guatemala"
}, {
  id: 90,
  value: "Guernsey"
}, {
  id: 91,
  value: "Guinea"
}, {
  id: 92,
  value: "Guinea-Bissau"
}, {
  id: 93,
  value: "Guyana"
}, {
  id: 94,
  value: "Haiti"
}, {
  id: 95,
  value: "Heard Island and Mcdonald Islands"
}, {
  id: 96,
  value: "Holy See (Vatican City State)"
}, {
  id: 97,
  value: "Honduras"
}, {
  id: 98,
  value: "Hong Kong"
}, {
  id: 99,
  value: "Hungary"
}, {
  id: 100,
  value: "Iceland"
}, {
  id: 101,
  value: "India"
}, {
  id: 102,
  value: "Indonesia"
}, {
  id: 103,
  value: "Iran, Islamic Republic Of"
}, {
  id: 104,
  value: "Iraq"
}, {
  id: 105,
  value: "Ireland"
}, {
  id: 106,
  value: "Isle of Man"
}, {
  id: 107,
  value: "Israel"
}, {
  id: 108,
  value: "Italy"
}, {
  id: 109,
  value: "Jamaica"
}, {
  id: 110,
  value: "Japan"
}, {
  id: 111,
  value: "Jersey"
}, {
  id: 112,
  value: "Jordan"
}, {
  id: 113,
  value: "Kazakhstan"
}, {
  id: 114,
  value: "Kenya"
}, {
  id: 115,
  value: "Kiribati"
}, {
  id: 116,
  value: "Korea, Democratic Peoples Republic of"
}, {
  id: 117,
  value: "Korea, Republic of"
}, {
  id: 118,
  value: "Kuwait"
}, {
  id: 119,
  value: "Kyrgyzstan"
}, {
  id: 120,
  value: "Lao Peoples Democratic Republic"
}, {
  id: 121,
  value: "Latvia"
}, {
  id: 122,
  value: "Lebanon"
}, {
  id: 123,
  value: "Lesotho"
}, {
  id: 124,
  value: "Liberia"
}, {
  id: 125,
  value: "Libyan Arab Jamahiriya"
}, {
  id: 126,
  value: "Liechtenstein"
}, {
  id: 127,
  value: "Lithuania"
}, {
  id: 128,
  value: "Luxembourg"
}, {
  id: 129,
  value: "Macao"
}, {
  id: 130,
  value: "Macedonia, The Former Yugoslav Republic of"
}, {
  id: 131,
  value: "Madagascar"
}, {
  id: 132,
  value: "Malawi"
}, {
  id: 133,
  value: "Malaysia"
}, {
  id: 134,
  value: "Maldives"
}, {
  id: 135,
  value: "Mali"
}, {
  id: 136,
  value: "Malta"
}, {
  id: 137,
  value: "Marshall Islands"
}, {
  id: 138,
  value: "Martinique"
}, {
  id: 139,
  value: "Mauritania"
}, {
  id: 140,
  value: "Mauritius"
}, {
  id: 141,
  value: "Mayotte"
}, {
  id: 142,
  value: "Mexico"
}, {
  id: 143,
  value: "Micronesia, Federated States of"
}, {
  id: 144,
  value: "Moldova, Republic of"
}, {
  id: 145,
  value: "Monaco"
}, {
  id: 146,
  value: "Mongolia"
}, {
  id: 194,
  value: "Montenegro"
}, {
  id: 147,
  value: "Montserrat"
}, {
  id: 148,
  value: "Morocco"
}, {
  id: 149,
  value: "Mozambique"
}, {
  id: 150,
  value: "Myanmar"
}, {
  id: 151,
  value: "Namibia"
}, {
  id: 152,
  value: "Nauru"
}, {
  id: 153,
  value: "Nepal"
}, {
  id: 154,
  value: "Netherlands"
}, {
  id: 155,
  value: "Netherlands Antilles"
}, {
  id: 156,
  value: "New Caledonia"
}, {
  id: 157,
  value: "New Zealand"
}, {
  id: 158,
  value: "Nicaragua"
}, {
  id: 159,
  value: "Niger"
}, {
  id: 160,
  value: "Nigeria"
}, {
  id: 161,
  value: "Niue"
}, {
  id: 162,
  value: "Norfolk Island"
}, {
  id: 163,
  value: "Northern Mariana Islands"
}, {
  id: 164,
  value: "Norway"
}, {
  id: 165,
  value: "Oman"
}, {
  id: 166,
  value: "Pakistan"
}, {
  id: 167,
  value: "Palau"
}, {
  id: 168,
  value: "Palestinian Territory, Occupied"
}, {
  id: 169,
  value: "Panama"
}, {
  id: 170,
  value: "Papua New Guinea"
}, {
  id: 171,
  value: "Paraguay"
}, {
  id: 172,
  value: "Peru"
}, {
  id: 173,
  value: "Philippines"
}, {
  id: 174,
  value: "Pitcairn"
}, {
  id: 175,
  value: "Poland"
}, {
  id: 176,
  value: "Portugal"
}, {
  id: 177,
  value: "Puerto Rico"
}, {
  id: 178,
  value: "Qatar"
}, {
  id: 179,
  value: "Reunion"
}, {
  id: 180,
  value: "Romania"
}, {
  id: 181,
  value: "Russian Federation"
}, {
  id: 182,
  value: "RWANDA"
}, {
  id: 183,
  value: "Saint Helena"
}, {
  id: 184,
  value: "Saint Kitts and Nevis"
}, {
  id: 185,
  value: "Saint Lucia"
}, {
  id: 186,
  value: "Saint Pierre and Miquelon"
}, {
  id: 187,
  value: "Saint Vincent and the Grenadines"
}, {
  id: 188,
  value: "Samoa"
}, {
  id: 189,
  value: "San Marino"
}, {
  id: 190,
  value: "Sao Tome and Principe"
}, {
  id: 191,
  value: "Saudi Arabia"
}, {
  id: 192,
  value: "Senegal"
}, {
  id: 193,
  value: "Serbia"
}, {
  id: 195,
  value: "Seychelles"
}, {
  id: 196,
  value: "Sierra Leone"
}, {
  id: 197,
  value: "Singapore"
}, {
  id: 198,
  value: "Slovakia"
}, {
  id: 199,
  value: "Slovenia"
}, {
  id: 200,
  value: "Solomon Islands"
}, {
  id: 201,
  value: "Somalia"
}, {
  id: 202,
  value: "South Africa"
}, {
  id: 203,
  value: "South Georgia and the South Sandwich Islands"
}, {
  id: 204,
  value: "Spain"
}, {
  id: 205,
  value: "Sri Lanka"
}, {
  id: 206,
  value: "Sudan"
}, {
  id: 207,
  value: "Suriname"
}, {
  id: 208,
  value: "Svalbard and Jan Mayen"
}, {
  id: 209,
  value: "Swaziland"
}, {
  id: 210,
  value: "Sweden"
}, {
  id: 211,
  value: "Switzerland"
}, {
  id: 212,
  value: "Syrian Arab Republic"
}, {
  id: 213,
  value: "Taiwan, Province of China"
}, {
  id: 214,
  value: "Tajikistan"
}, {
  id: 215,
  value: "Tanzania, United Republic of"
}, {
  id: 216,
  value: "Thailand"
}, {
  id: 217,
  value: "Timor-Leste"
}, {
  id: 218,
  value: "Togo"
}, {
  id: 219,
  value: "Tokelau"
}, {
  id: 220,
  value: "Tonga"
}, {
  id: 221,
  value: "Trinidad and Tobago"
}, {
  id: 222,
  value: "Tunisia"
}, {
  id: 223,
  value: "Turkey"
}, {
  id: 224,
  value: "Turkmenistan"
}, {
  id: 225,
  value: "Turks and Caicos Islands"
}, {
  id: 226,
  value: "Tuvalu"
}, {
  id: 227,
  value: "Uganda"
}, {
  id: 228,
  value: "Ukraine"
}, {
  id: 229,
  value: "United Arab Emirates"
}, {
  id: 230,
  value: "United Kingdom"
}, {
  id: 231,
  value: "United States"
}, {
  id: 232,
  value: "United States Minor Outlying Islands"
}, {
  id: 233,
  value: "Uruguay"
}, {
  id: 234,
  value: "Uzbekistan"
}, {
  id: 235,
  value: "Vanuatu"
}, {
  id: 236,
  value: "Venezuela"
}, {
  id: 237,
  value: "Viet Nam"
}, {
  id: 238,
  value: "Virgin Islands, British"
}, {
  id: 239,
  value: "Virgin Islands, U.S."
}, {
  id: 240,
  value: "Wallis and Futuna"
}, {
  id: 241,
  value: "Western Sahara"
}, {
  id: 242,
  value: "Yemen"
}, {
  id: 243,
  value: "Zambia"
}, {
  id: 244,
  value: "Zimbabwe"
}]
  