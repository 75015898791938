const hostName = window.location.hostname;
const hostPort = window.location.port;

const getHost = () => {
  const protocol = window.location.protocol;
  const host = window.location.host;
  return `${protocol}//${host}`;
};

const _isDev = () => { // hostName.match(/etracker.local/i) 
  if (hostName === "localhost" || hostName === "127.0.0.1" || hostPort.indexOf('4200') > -1) {
    return true;
  } else {
    return false;
  }
}

const apiURI = _isDev() ? 'http://localhost:4200/api/' : `/api/`;

export const ENV = {
  BASE_URI: getHost(),
  BASE_API: apiURI
};
