import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

import { ENV } from '../../../core/env.config';
import { AuthService } from '../../../auth/auth.service';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.css']
})
export class SignInComponent implements OnInit {
  isLoginError: boolean = false;

  constructor(
    private http: HttpClient, 
    private authService: AuthService, 
    private router: Router, 
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    if (this.authService.loggedIn) {
      this.router.navigate(['/member'])
    }
  }

  onSubmit(email, password) {
    this.http.post(`${ENV.BASE_API}login`, {email: email, password: password}).subscribe((authResponse: any) => {
      const authResult = authResponse.result;
      this.authService.handleAuth(authResult);
      if (authResult.hasOwnProperty('errors')) {
        let errors = authResult.errors;
        errors.forEach((message, index) => { 
          this.toastr.warning(message); 
        }) 
        this.router.navigate(['/login']) 
      } else {
        if (this.authService.isAdmin) {
          this.router.navigate(['/admin'])
        } else {
          this.router.navigate(['/member'])
        }
        this.toastr.success('Successfully logged in.');
      }
    }, (err: HttpErrorResponse) => {
      this.isLoginError = true;
      this.toastr.error(`Error: ${err.error}`);
    }, () => {
      // always
    });
  }

}
