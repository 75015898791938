import { Component, OnInit, Input } from '@angular/core';
import { Helpers } from '../../core/helpers';
import * as Cookies from "js-cookie";
// @see https://github.com/7leads/ngx-cookie-service

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {

  @Input() menuItems: any[];

  constructor() { }

  ngOnInit() {
    
    setTimeout(() => {
      this.initHandlers();
    }, 1e3/3);

    Helpers.sidebarInit();
  }
 
  closeMobileSidebar(e) {
    e.preventDefault();
    Helpers.toggleSidebar('off', 'mobile');
  }

  initHandlers() {

    let elements = document.querySelectorAll('ul.m-menu__nav li a');
    for (let i = 0, total = elements.length; i < total; i++) {
      elements[i].addEventListener('click', (e) => {

        let shown = document.querySelectorAll('.m-menu__item--open');
        console.log('shown', shown)

        let parent = e.srcElement.parentNode.parentElement;
        if (parent.classList.contains('m-menu__item--open')) {
          parent.classList.remove('m-menu__item--open');
        } else {
          parent.classList.add('m-menu__item--open');
        }
      }, false);
    }

  }

}
