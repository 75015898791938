class UserModel {
  constructor(
    public first_name,
    public last_name,
    public password,
    public identity,
    public email,
    public enabled,
    public token,
    public address1,
    public address2,
    public city,
    public state,
    public country,
    public zip,
    public home_phone,
    public mobile_phone,
    public last_login,
    public created,
    public updated,
    public id?: string
  ) { }
}

class RolesModel {
  constructor(
    public id: string,
    public authority: string,
  ) { }
}

export { UserModel, RolesModel }
