import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-notfound',
  templateUrl: './notfound.component.html',
  styleUrls: ['./notfound.component.css']
})
export class NotfoundComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    console.log('admin-component')
    //@ts-ignore
    document.getElementById('theme-admin').disabled = false; 
    //@ts-ignore
    document.getElementById('theme-member').disabled = true; 
    // update body styles
    document.body.classList.remove("m-page--wide", "m-header--fixed", "m-header--fixed-mobile", "m-footer--push", "m-aside--offcanvas-default");
    document.body.classList.add("m-page--fluid", "m--skin-", "m-content--skin-light2", "m-header--fixed", "m-header--fixed-mobile", "m-aside-left--enabled", "m-aside-left--skin-dark", "m-aside-left--offcanvas", "m-footer--push", "m-aside--offcanvas-default");
  }

}
