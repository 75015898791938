import { 
  HttpRequest, 
  HttpResponse, 
  HttpHandler,  
  HttpEvent, 
  HttpInterceptor,
  HttpErrorResponse 
} from "@angular/common/http";

import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { tap } from 'rxjs/operators';

import { AuthService } from "./auth.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private auth: AuthService
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (localStorage.getItem('userToken')) {
      const cloned = req.clone({
        headers: req.headers.set("Authorization", "Bearer " + localStorage.getItem('userToken'))
      });
      return next.handle(cloned).pipe(
        tap(
          res => this._onNext(res), 
          error => this._onError(error)
        )
      );
    } else {
      return next.handle(req).pipe(
        tap(
          res => this._onNext(res), 
          error => this._onError(error)
        )
      );
    }
  }

  private _onNext(res) {
    if (res instanceof HttpResponse) {
      console.log(`HttpResponse: ${res.status}: ${res.statusText}`);
    }
  }

  private _onError(error) {
    if (error instanceof HttpErrorResponse) {
      const msg = error.message;
      if (error.status === 401 || msg.indexOf('NOK') > -1 || msg.indexOf('Unauthorized') > -1) {
        console.log(`HttpErrorResponse: ${error}`)
        this.auth.logout();
      }
    }
  }

}
