import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { AuthService } from './auth.service';

import { AuthGuard } from './auth.guard';
import { RoleGuard } from './role.guard';
import { AdminGuard } from './admin.guard';
import { AuthInterceptor } from './auth.interceptor';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: []
})
export class AuthModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: AuthModule,
      providers: [
        AuthService,
        AuthGuard,
        AdminGuard,
        RoleGuard,
        {
          provide : HTTP_INTERCEPTORS,
          useClass : AuthInterceptor,
          multi : true
        }
      ]
    };
  }
}
