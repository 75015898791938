import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule} from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';

// Routing
import { CoreRoutingModule } from './core-routing.module';

// Components
import { NotfoundComponent } from './notfound/notfound.component';

// Modules
import { AuthModule } from '../auth/auth.module';
import { FrontModule } from '../front/front.module';
import { MemberModule } from '../member/member.module';
import { AdminModule } from '../admin/admin.module';
import { GravatarModule } from './gravatar/gravatar.module';
import { UiModule } from '../ui/ui.module';

// Services
import { UserService } from './service/user.service';
import { NetworkService } from './service/network.service';
import { LocationService } from './service/location.service';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    GravatarModule,
    UiModule,
    FrontModule,
    MemberModule,
    AdminModule,
    CoreRoutingModule,
    ToastrModule.forRoot(),
    AuthModule.forRoot()
  ],
  declarations: [ 
    NotfoundComponent
  ],
  exports: [
    RouterModule
  ],
  providers: [
    UserService, 
    NetworkService,
    LocationService
  ]
})
export class CoreModule { }
