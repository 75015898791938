import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { GravatarService } from '../../core/gravatar/gravatar.service';
import { Helpers } from '../../core/helpers';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  gravatarUrl: String;

  constructor(
    public auth: AuthService, 
    public gravatar: GravatarService
  ) { 
    if (auth.userProfile) {
      this.gravatarUrl = gravatar.url(auth.userProfile.email, 128, 'mm');
    }
  }

  ngOnInit() {
  }

  toggleDesktopSidebar(e) {
    e.preventDefault();
    Helpers.toggleSidebar('any');
  }

  toggleMobileSidebar(e) {
    e.preventDefault();
    Helpers.toggleSidebar('any', 'mobile')
  }

  toggleMobileHeaderMenu(e) {
    e.preventDefault();
    let body = document.getElementsByTagName("body")[0];
    if (body.classList.contains('m-topbar--on')){
      body.classList.remove('m-topbar--on');
    } else {
      body.classList.add('m-topbar--on');
    }
  }

  // handle avatar click
  toggleUserOptions(e) {
    e.preventDefault();

    let target = e.currentTarget;
    let parent = target.parentNode;

    if (parent.classList.contains('m-dropdown--open')){
      parent.classList.remove('m-dropdown--open');
    } else {
      parent.classList.add('m-dropdown--open');
    }

    console.log(e.currentTarget.parentNode)
    console.log('toggleUserOptions')
  }

}
