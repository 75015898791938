import { NgModule } from '@angular/core';

// directives
import { RequiredLabelDirective } from './directives/required-label.directive';
import { RequiredFieldDirective } from './directives/required-field.directive';

// pipes
import { KeysPipe } from './pipes/keys.pipe';
import { SafeUrlPipe } from './pipes/safe-url.pipe';
import { ReversePipe } from './pipes/reverse.pipe';
import { FromNowPipe } from './pipes/from-now.pipe';

@NgModule({
  declarations: [
    RequiredLabelDirective,
    RequiredFieldDirective,
    KeysPipe,
    SafeUrlPipe,
    ReversePipe,
    FromNowPipe
  ],
  exports: [
    RequiredLabelDirective,
    RequiredFieldDirective,
    KeysPipe,
    SafeUrlPipe,
    ReversePipe,
    FromNowPipe
  ]
})
export class SharedModule { }
