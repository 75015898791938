import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminComponent } from './admin.component';
import { DashboardComponent } from './dashboard/dashboard.component';

import { UserListComponent } from './pages/user-list/user-list.component';
import { UserFormComponent } from './pages/user-form/user-form.component';

const routes: Routes = [{
    path: 'admin',
    component: AdminComponent,
    children: [{ 
      path: '', 
      component: DashboardComponent
    }, {
      path: 'user',
      component: UserListComponent
    }, {
      path: 'user/edit/id/:id',
      component: UserFormComponent
    }]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminRoutingModule { }
