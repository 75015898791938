import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable()
export class RoleGuard implements CanActivate {

  constructor(private auth: AuthService, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const roles = this.auth.userProfile.roles;
    const expectedRole = route.data.expects;
    if (roles.indexOf(expectedRole) !== -1) {
      console.log(`Granted: ${expectedRole}`)
      return true;
    } else {
      this.router.navigate(['/login']);
      return false;
    }
  }

}
