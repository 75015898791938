import { Component, OnInit, isDevMode } from '@angular/core'
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { NgForm } from '@angular/forms'
import { Router } from '@angular/router'
import { ToastrService } from 'ngx-toastr';

import { ENV } from '../../../core/env.config';
import { AuthService } from '../../../auth/auth.service';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.css']
})
export class SignUpComponent implements OnInit {
  user: any;
  isLoginError: boolean = false;
  emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";

  constructor(private http: HttpClient, private authService: AuthService, private router: Router, private toastr: ToastrService) {
  }

  ngOnInit() {
    this.resetForm();
  }

  resetForm(form?: NgForm) {
    
    if (form != null) {
      form.reset();
    }
    
    this.user = {
      id: '',
      email: '',
      username: '',
      password: '',
      role: ''
    }
  }

  onSubmit(form: NgForm) {
    return this.http.post(`${ENV.BASE_API}register`, form.value).subscribe((authResponse: any) => {
      const authResult = authResponse.result;
      this.authService.handleAuth(authResult);
      if (authResult.hasOwnProperty('errors')) {
        let errors = authResult.errors;
        errors.forEach((message, index) => { 
          this.toastr.warning(message); 
        }) 
        this.router.navigate(['/login']) 
      } else {
        this.resetForm(form);
        this.toastr.success('Account has been successfully registered.');
        this.router.navigate(['/'])
      }
    }, (err: HttpErrorResponse) => {
      this.isLoginError = true;
      this.toastr.error(`Error authenticating: ${err.error}`);
    }, () => {
      // always
    });
  }

}
