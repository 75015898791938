import { Component, OnInit, ViewChild } from '@angular/core';
import { LocationService } from '../../core/service/location.service';

// @see https://stackoverflow.com/questions/51677452/angular-6-application-cannot-find-namespace-google

@Component({
  selector: 'app-tracker',
  templateUrl: './tracker.component.html',
  styleUrls: ['./tracker.component.css']
})
export class TrackerComponent implements OnInit {

  @ViewChild('gmap') gmapElement: any;
  map: google.maps.Map;

  isTracking = false;

  mapProps: any;

  currentLat: any;
  currentLng: any;

  marker: google.maps.Marker;
  arrow: google.maps.Marker;
  circle: google.maps.Circle;

  errors: any[] = [];
  trackingData: any[] = [];
  directionData: any[] = [];

  constructor(
    private loc: LocationService
  ) {}

  ngOnInit() {
    this.mapProps = {
      center: new google.maps.LatLng(32.750645, -96.681587),
      zoom: 15,
      mapTypeId: google.maps.MapTypeId.ROADMAP
    };
    this.map = new google.maps.Map(this.gmapElement.nativeElement, this.mapProps);
  }

  findMe(showCircle: boolean = true) {

    let options = {
      enableHighAccuracy: true,
      //timeout: 0,
      //maximumAge: 0
    }

    if (this.loc.isEnabled()) {
      this.loc.getLocation(options).subscribe(
        position => {
          this.showPosition(position, true);
        },
        error => {
          this.errors.push(error);
        }
      );
    } else {
      this.errors.push("Geolocation API is not supported by this browser.");
    }
  }

  trackMe() {
    if (this.loc.isEnabled()) {
      this.loc.watchPosition({}).subscribe(
        position => {
          this.isTracking = true;
          this.showPosition(position, true);
        },
        error => {
          this.errors.push(error);
        }
      );
    } else {
      this.errors.push("Geolocation API is not supported by this browser.");
    }
  }

  showPosition(position, showCircle: boolean = false) {
    console.log(`Tracking postion:  ${position.coords.latitude} - ${position.coords.longitude}`);

    let data = <Coordinates>position.coords;
    let latitude = data.latitude;
    let longitude = data.longitude;

    this.trackingData.push({
      accuracy: data.accuracy,
      altitude: data.altitude,
      altitudeAccuracy: data.altitudeAccuracy,
      heading: data.heading,
      latitude: data.latitude,
      longitude: data.longitude,
      speed: data.longitude,
      timestamp: position.timestamp
    });

    if (this.trackingData.length) {
      let distance = this.calcDistance(data.latitude, data.longitude);
      console.log(`The distance traveled: ${distance}`);
    }

    let location = new google.maps.LatLng(latitude, longitude);
    this.map.panTo(location);

    this.directionData.push(new google.maps.LatLng(latitude, longitude));

    if (this.directionData.length>1) {

      let lastLatitude = this.directionData[this.directionData.length-1].lat();
      let lastLongitude = this.directionData[this.directionData.length-1].lng();

      let from = new google.maps.LatLng(lastLatitude, lastLongitude);
      let to = new google.maps.LatLng(latitude, longitude);

      this.arrow = new google.maps.Marker({
        position: to,
        map: this.map,
        draggable: false,
        title: 'Direction'
      });
    
      let heading = google.maps.geometry.spherical.computeHeading(from, to);
    
      this.arrow.setIcon({
        path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
        scale: 4,
        rotation: heading
      });
    }

    if (!this.marker) {
      this.marker = new google.maps.Marker({
        position: location,
        map: this.map,
        title: 'Current Location'
      });
    } else {
      this.marker.setPosition(location);
    }

    if (this.trackingData.length && showCircle) {
      this.circle = new google.maps.Circle({
        strokeColor: '#2e6da4',
        strokeOpacity: 0.8,
        strokeWeight: 1.5,
        fillColor: '#337ab7',
        fillOpacity: 0.6,
        map: this.map,
        center: {
          lat: latitude, 
          lng: longitude
        },
        radius: 300
      });
    }

    this.currentLat = latitude;
    this.currentLng = longitude;

  }

  calcDistance(lat, lon) {
    let p1 = new google.maps.LatLng(this.trackingData[0].latitude, this.trackingData[0].longitude);
    let p2 = new google.maps.LatLng(lat, lon);
    return google.maps.geometry.spherical.computeDistanceBetween(p1, p2);
  }

}
