import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { MemberRoutingModule } from './member-routing.module';
import { SharedModule } from '../shared/shared.module';
import { UiModule } from '../ui/ui.module';

import { MemberComponent } from './member.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { TrackerComponent } from './tracker/tracker.component';

@NgModule({
  imports: [
    CommonModule,
    MemberRoutingModule,
    FormsModule,
    SharedModule,
    UiModule
  ],
  declarations: [
    MemberComponent,
    DashboardComponent,
    TrackerComponent
  ]
})
export class MemberModule { }
