import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { FrontComponent } from './front.component';
import { UserComponent } from './user/user.component';
import { SignInComponent } from './user/sign-in/sign-in.component';

const routes: Routes = [{
    path: '',
    component: FrontComponent,
    children: [{
      path: '',
      component: UserComponent,
      children: [{
        path: '',
        component: SignInComponent
      }]
    }, {
      path: 'login',
      component: UserComponent,
      children: [{
        path: '',
        component: SignInComponent
      }]
    }]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FrontRoutingModule { }
