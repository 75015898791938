import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { throwError as ObservableThrowError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ENV } from '../env.config';
import { UserModel } from '../models/user.model';
import { AuthService } from '../../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private http: HttpClient,
    private auth: AuthService
  ) { }

  private get _authHeader(): string {
    return `Bearer ${this.auth.accessToken}`;
  }

  fetchGrid$(options: any): Observable<any> {
    return this.http
      .post<UserModel[]>(`${ENV.BASE_API}admin/user`, options)
      .pipe(
        catchError((error) => this._handleError(error))
      );
  }

  // GET an user by ID (login required)
  getUserById$(id: string): Observable<any> {
    return this.http
      .get<UserModel>(`${ENV.BASE_API}admin/user/edit/id/${id}`, {
        headers: new HttpHeaders().set('Authorization', this._authHeader)
      })
      .pipe(
        catchError((error) => this._handleError(error))
      );
  }

  // PUT existing USER (login required)
  editUser$(id: string, user: UserModel): Observable<UserModel> {
    console.log('UserModel', user)
    return this.http
      .put(`${ENV.BASE_API}admin/user/edit/id/${id}`, user, {
        headers: new HttpHeaders().set('Authorization', this._authHeader)
      })
      .pipe(
        catchError((error) => this._handleError(error))
      );
  }

  addUser$(user: UserModel): Observable<UserModel> {
    return this.http
      .post<UserModel>(`${ENV.BASE_API}admin/user/add`, user, {
        headers: new HttpHeaders().set('Authorization', this._authHeader)
      })
      .pipe(
        catchError((error) => this._handleError(error))
      );
  }

  private _handleError(err: HttpErrorResponse | any): Observable<any> {
    const errorMsg = err.message || 'Error: Unable to complete request.';
    if (err.message) {
      console.warn(errorMsg)
    }
    return ObservableThrowError(errorMsg);
  }
}
