import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';

const GEOLOCATION_ERRORS = {
	'errors.location.unsupportedBrowser': 'Browser does not support location services.',
	'errors.location.permissionDenied': 'You have rejected access to your location.',
	'errors.location.positionUnavailable': 'Unable to determine your location.',
	'errors.location.timeout': 'Service timeout has been reached.'
};

@Injectable()
export class LocationService {

  geoLocationEnabled: boolean = false;

  constructor() {
    if (window.navigator && window.navigator.geolocation) {
      this.geoLocationEnabled = true;
    }
   }

  isEnabled(): Boolean {
    return this.geoLocationEnabled;
  }

	public getLocation(opts: object): Observable<any> {
		return Observable.create(observer => {
			if (this.geoLocationEnabled) {
				window.navigator.geolocation.getCurrentPosition(
					(position) => {
						observer.next(position);
            observer.complete();
					},
					(error) => {
            this.handleError(observer, error);
					},
					opts);
			} else {
				observer.error(GEOLOCATION_ERRORS['errors.location.unsupportedBrowser']);
			}
		});
  }

	public watchPosition(opts: object): Observable<any> {
		return Observable.create(observer => {
			if (this.geoLocationEnabled) {
				window.navigator.geolocation.watchPosition(
					(position) => {
						observer.next(position);
					},
					(error) => {
            this.handleError(observer, error);
					},
					opts);
			} else {
				observer.error(GEOLOCATION_ERRORS['errors.location.unsupportedBrowser']);
			}
		});
  }
  
  public handleError(observer, error): void {
    switch (error.code) {
      case 1:
        observer.error(GEOLOCATION_ERRORS['errors.location.permissionDenied']);
        break;
      case 2:
        observer.error(GEOLOCATION_ERRORS['errors.location.positionUnavailable']);
        break;
      case 3:
        observer.error(GEOLOCATION_ERRORS['errors.location.timeout']);
        break;
    }
  }

}
