export const adminMenu: any[] = [{
  title: 'User Management',
  link: '/admin/user',
  icon: 'm-menu__link-icon flaticon-tabs',
  }, {
  title: "Base",
  link: "/",
  icon: "m-menu__link-icon flaticon-layers",
  children: [{
      title: "State Colors",
      link: "/",
      icon: "m-menu__link-bullet m-menu__link-bullet--dot"
  }, {
      title: "Typography",
      link: "/",
      icon: "m-menu__link-bullet m-menu__link-bullet--dot"
  }, {
      title: "Stack",
      link: "/",
      icon: "m-menu__link-bullet m-menu__link-bullet--dot"
  }, {
      title: "Tables",
      link: "/",
      icon: "m-menu__link-bullet m-menu__link-bullet--dot"
  }, {
      title: "Progress",
      link: "/",
      icon: "m-menu__link-bullet m-menu__link-bullet--dot"
  }, {
      title: "Modal",
      link: "/",
      icon: "m-menu__link-bullet m-menu__link-bullet--dot"
  }, {
      title: "Alerts",
      link: "/",
      icon: "m-menu__link-bullet m-menu__link-bullet--dot"
  }, {
      title: "Popover",
      link: "/",
      icon: "m-menu__link-bullet m-menu__link-bullet--dot"
  }, {
      title: "Tooltip",
      link: "/",
      icon: "m-menu__link-bullet m-menu__link-bullet--dot"
  }, {
      title: "Block UI",
      link: "/",
      icon: "m-menu__link-bullet m-menu__link-bullet--dot"
  }, {
      title: "Spinners",
      link: "/",
      icon: "m-menu__link-bullet m-menu__link-bullet--dot"
  }, {
      title: "Scrollable",
      link: "/",
      icon: "m-menu__link-bullet m-menu__link-bullet--dot"
  }, {
      title: "Dropdown",
      link: "/",
      icon: "m-menu__link-bullet m-menu__link-bullet--dot"
  }, {
      title: "Tabs",
      link: "/",
      icon: "m-menu__link-bullet m-menu__link-bullet--dot",
      children: [{
          title: "Bootstrap Tabs",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }, {
          title: "Line Tabs",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }]
  }, {
      title: "Accordions",
      link: "/",
      icon: "m-menu__link-bullet m-menu__link-bullet--dot"
  }, {
      title: "Navs",
      link: "/",
      icon: "m-menu__link-bullet m-menu__link-bullet--dot"
  }, {
      title: "Lists",
      link: "/",
      icon: "m-menu__link-bullet m-menu__link-bullet--dot"
  }, {
      title: "Tree View",
      link: "/",
      icon: "m-menu__link-bullet m-menu__link-bullet--dot"
  }, {
      title: "Bootstrap Notify",
      link: "/",
      icon: "m-menu__link-bullet m-menu__link-bullet--dot"
  }, {
      title: "Toastr",
      link: "/",
      icon: "m-menu__link-bullet m-menu__link-bullet--dot"
  }, {
      title: "SweetAlert2",
      link: "/",
      icon: "m-menu__link-bullet m-menu__link-bullet--dot"
  }]
}, {
  title: "Icons",
  link: "/",
  icon: "m-menu__link-icon flaticon-share",
  children: [{
      title: "Flaticon",
      link: "/",
      icon: "m-menu__link-bullet m-menu__link-bullet--dot"
  }, {
      title: "Fontawesome",
      link: "/",
      icon: "m-menu__link-bullet m-menu__link-bullet--dot"
  }, {
      title: "Lineawesome",
      link: "/",
      icon: "m-menu__link-bullet m-menu__link-bullet--dot"
  }, {
      title: "Socicons",
      link: "/",
      icon: "m-menu__link-bullet m-menu__link-bullet--dot"
  }]
}, {
  title: "Buttons",
  link: "/",
  icon: "m-menu__link-icon flaticon-multimedia-1",
  children: [{
      title: "Button Base",
      link: "/",
      icon: "m-menu__link-bullet m-menu__link-bullet--dot",
      children: [{
          title: "Default Style",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }, {
          title: "Square Style",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }, {
          title: "Pill Style",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }, {
          title: "Air Style",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }]
  }, {
      title: "Button Group",
      link: "/",
      icon: "m-menu__link-bullet m-menu__link-bullet--dot"
  }, {
      title: "Button Dropdown",
      link: "/",
      icon: "m-menu__link-bullet m-menu__link-bullet--dot"
  }, {
      title: "Button Icon",
      link: "/",
      icon: "m-menu__link-bullet m-menu__link-bullet--dot",
      children: [{
          title: "Lineawesome Icons",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }, {
          title: "Fontawesome Icons",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }, {
          title: "Flaticon Icons",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }]
  }]
}, {
  title: "Forms",
  link: "/",
  icon: "m-menu__link-icon flaticon-interface-7",
  children: [{
      title: "Form Controls",
      link: "/",
      icon: "m-menu__link-bullet m-menu__link-bullet--dot",
      children: [{
          title: "Base Inputs",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }, {
          title: "Checkbox Radio",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }, {
          title: "Input Groups",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }, {
          title: "Switch",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }, {
          title: "Mega Options",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }]
  }, {
      title: "Form Widgets",
      link: "/",
      icon: "m-menu__link-bullet m-menu__link-bullet--dot",
      children: [{
          title: "Datepicker",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }, {
          title: "Datetimepicker",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }, {
          title: "Timepicker",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }, {
          title: "Daterangepicker",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }, {
          title: "Touchspin",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }, {
          title: "Maxlength",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }, {
          title: "Switch",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }, {
          title: "Multiple Select Splitter",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }, {
          title: "Bootstrap Select",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }, {
          title: "Select2",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }, {
          title: "Typeahead",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }, {
          title: "noUiSlider",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }, {
          title: "Form Repeater",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }, {
          title: "Ion Range Slider",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }, {
          title: "Input Masks",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }, {
          title: "Summernote WYSIWYG",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }, {
          title: "Markdown Editor",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }, {
          title: "Autosize",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }, {
          title: "Clipboard",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }, {
          title: "Dropzone",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }, {
          title: "Google reCaptcha",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }]
  }, {
      title: "Form Layouts",
      link: "/",
      icon: "m-menu__link-bullet m-menu__link-bullet--dot",
      children: [{
          title: "Default Forms",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }, {
          title: "Multi Column Forms",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }, {
          title: "Action Bars",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }]
  }, {
      title: "Form Validation",
      link: "/",
      icon: "m-menu__link-bullet m-menu__link-bullet--dot",
      children: [{
          title: "Validation States",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }, {
          title: "Form Controls",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }, {
          title: "Form Widgets",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }]
  }, {
      title: "Form Wizard",
      link: "/",
      icon: "m-menu__link-bullet m-menu__link-bullet--dot",
      children: [{
          title: "Form Wizard 1",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }, {
          title: "Form Wizard 2",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }, {
          title: "Form Wizard 3",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }, {
          title: "Form Wizard 4",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }, {
          title: "Form Wizard 5",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }]
  }]
}, {
  title: "Datatables",
  link: "/",
  icon: "m-menu__link-icon flaticon-tabs",
  children: [{
      title: "Base",
      link: "/",
      icon: "m-menu__link-bullet m-menu__link-bullet--dot",
      children: [{
          title: "Local Data",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }, {
          title: "JSON Data",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }, {
          title: "Ajax Data",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }, {
          title: "HTML Table",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }, {
          title: "Record Selection",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }, {
          title: "Local Sort",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }, {
          title: "Row Details",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }, {
          title: "Column Rendering",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }, {
          title: "Column Width",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }, {
          title: "Responsive Columns",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }, {
          title: "Translation",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }]
  }, {
      title: "Scrolling",
      link: "/",
      icon: "m-menu__link-bullet m-menu__link-bullet--dot",
      children: [{
          title: "Vertical Scrolling",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }, {
          title: "Horizontal Scrolling",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }, {
          title: "Both Scrolling",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }]
  }, {
      title: "Locked Columns",
      link: "/",
      icon: "m-menu__link-bullet m-menu__link-bullet--dot",
      children: [{
          title: "Left Locked Columns",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }, {
          title: "Right Locked Columns",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }, {
          title: "Both Locked Columns",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }, {
          title: "HTML Table",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }]
  }, {
      title: "Child Datatables",
      link: "/",
      icon: "m-menu__link-bullet m-menu__link-bullet--dot",
      children: [{
          title: "Local Data",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }, {
          title: "Remote Data",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }]
  }, {
      title: "API",
      link: "/",
      icon: "m-menu__link-bullet m-menu__link-bullet--dot",
      children: [{
          title: "API Methods",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }, {
          title: "Events",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }]
  }]
}, {
  title: "Portlets",
  link: "/",
  icon: "m-menu__link-icon flaticon-interface-1",
  children: [{
      title: "Base Portlets",
      link: "/",
      icon: "m-menu__link-bullet m-menu__link-bullet--dot"
  }, {
      title: "Advanced Portlets",
      link: "/",
      icon: "m-menu__link-bullet m-menu__link-bullet--dot"
  }, {
      title: "Creative Portlets",
      link: "/",
      icon: "m-menu__link-bullet m-menu__link-bullet--dot"
  }, {
      title: "Tabbed Portlets",
      link: "/",
      icon: "m-menu__link-bullet m-menu__link-bullet--dot"
  }, {
      title: "Draggable Portlets",
      link: "/",
      icon: "m-menu__link-bullet m-menu__link-bullet--dot"
  }, {
      title: "Portlet Tools",
      link: "/",
      icon: "m-menu__link-bullet m-menu__link-bullet--dot"
  }]
}, {
  title: "Timeline",
  link: "/",
  icon: "m-menu__link-icon flaticon-interface-6",
  children: [{
      title: "Timeline 1",
      link: "/",
      icon: "m-menu__link-bullet m-menu__link-bullet--dot"
  }, {
      title: "Timeline 2",
      link: "/",
      icon: "m-menu__link-bullet m-menu__link-bullet--dot"
  }]
}, {
  title: "Widgets",
  link: "/",
  icon: "m-menu__link-icon flaticon-network",
  children: [{
      title: "General Widgets",
      link: "/",
      icon: "m-menu__link-bullet m-menu__link-bullet--dot"
  }, {
      title: "Chart Widgets",
      link: "/",
      icon: "m-menu__link-bullet m-menu__link-bullet--dot"
  }]
}, {
  title: "Calendar",
  link: "/",
  icon: "m-menu__link-icon flaticon-calendar",
  children: [{
      title: "Basic Calendar",
      link: "/",
      icon: "m-menu__link-bullet m-menu__link-bullet--dot"
  }, {
      title: "List Views",
      link: "/",
      icon: "m-menu__link-bullet m-menu__link-bullet--dot"
  }, {
      title: "Google Calendar",
      link: "/",
      icon: "m-menu__link-bullet m-menu__link-bullet--dot"
  }, {
      title: "External Events",
      link: "/",
      icon: "m-menu__link-bullet m-menu__link-bullet--dot"
  }, {
      title: "Background Events",
      link: "/",
      icon: "m-menu__link-bullet m-menu__link-bullet--dot"
  }]
}, {
  title: "Charts",
  link: "/",
  icon: "m-menu__link-icon flaticon-diagram",
  children: [{
      title: "amCharts",
      link: "/",
      icon: "m-menu__link-bullet m-menu__link-bullet--dot",
      children: [{
          title: "amCharts Charts",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }, {
          title: "amCharts Stock Charts",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }, {
          title: "amCharts Maps",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }]
  }, {
      title: "Flot Charts",
      link: "/",
      icon: "m-menu__link-bullet m-menu__link-bullet--dot"
  }, {
      title: "Google Charts",
      link: "/",
      icon: "m-menu__link-bullet m-menu__link-bullet--dot"
  }, {
      title: "Morris Charts",
      link: "/",
      icon: "m-menu__link-bullet m-menu__link-bullet--dot"
  }]
}, {
  title: "Maps",
  link: "/",
  icon: "m-menu__link-icon flaticon-placeholder-1",
  children: [{
      title: "Google Maps",
      link: "/",
      icon: "m-menu__link-bullet m-menu__link-bullet--dot"
  }, {
      title: "JQVMap",
      link: "/",
      icon: "m-menu__link-bullet m-menu__link-bullet--dot"
  }]
}, {
  title: "Utils",
  link: "/",
  icon: "m-menu__link-icon flaticon-signs-2",
  children: [{
      title: "Session Timeout",
      link: "/",
      icon: "m-menu__link-bullet m-menu__link-bullet--dot"
  }, {
      title: "Idle Timer",
      link: "/",
      icon: "m-menu__link-bullet m-menu__link-bullet--dot"
  }]
}]

export const memberMenu: any[] = [{
  title: 'Location Tracking',
  link: '/member/tracker',
  icon: 'm-menu__link-icon flaticon-route',
  }, {
  title: "Base",
  link: "/",
  icon: "m-menu__link-icon flaticon-layers",
  children: [{
      title: "State Colors",
      link: "/",
      icon: "m-menu__link-bullet m-menu__link-bullet--dot"
  }, {
      title: "Typography",
      link: "/",
      icon: "m-menu__link-bullet m-menu__link-bullet--dot"
  }, {
      title: "Stack",
      link: "/",
      icon: "m-menu__link-bullet m-menu__link-bullet--dot"
  }, {
      title: "Tables",
      link: "/",
      icon: "m-menu__link-bullet m-menu__link-bullet--dot"
  }, {
      title: "Progress",
      link: "/",
      icon: "m-menu__link-bullet m-menu__link-bullet--dot"
  }, {
      title: "Modal",
      link: "/",
      icon: "m-menu__link-bullet m-menu__link-bullet--dot"
  }, {
      title: "Alerts",
      link: "/",
      icon: "m-menu__link-bullet m-menu__link-bullet--dot"
  }, {
      title: "Popover",
      link: "/",
      icon: "m-menu__link-bullet m-menu__link-bullet--dot"
  }, {
      title: "Tooltip",
      link: "/",
      icon: "m-menu__link-bullet m-menu__link-bullet--dot"
  }, {
      title: "Block UI",
      link: "/",
      icon: "m-menu__link-bullet m-menu__link-bullet--dot"
  }, {
      title: "Spinners",
      link: "/",
      icon: "m-menu__link-bullet m-menu__link-bullet--dot"
  }, {
      title: "Scrollable",
      link: "/",
      icon: "m-menu__link-bullet m-menu__link-bullet--dot"
  }, {
      title: "Dropdown",
      link: "/",
      icon: "m-menu__link-bullet m-menu__link-bullet--dot"
  }, {
      title: "Tabs",
      link: "/",
      icon: "m-menu__link-bullet m-menu__link-bullet--dot",
      children: [{
          title: "Bootstrap Tabs",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }, {
          title: "Line Tabs",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }]
  }, {
      title: "Accordions",
      link: "/",
      icon: "m-menu__link-bullet m-menu__link-bullet--dot"
  }, {
      title: "Navs",
      link: "/",
      icon: "m-menu__link-bullet m-menu__link-bullet--dot"
  }, {
      title: "Lists",
      link: "/",
      icon: "m-menu__link-bullet m-menu__link-bullet--dot"
  }, {
      title: "Tree View",
      link: "/",
      icon: "m-menu__link-bullet m-menu__link-bullet--dot"
  }, {
      title: "Bootstrap Notify",
      link: "/",
      icon: "m-menu__link-bullet m-menu__link-bullet--dot"
  }, {
      title: "Toastr",
      link: "/",
      icon: "m-menu__link-bullet m-menu__link-bullet--dot"
  }, {
      title: "SweetAlert2",
      link: "/",
      icon: "m-menu__link-bullet m-menu__link-bullet--dot"
  }]
}, {
  title: "Icons",
  link: "/",
  icon: "m-menu__link-icon flaticon-share",
  children: [{
      title: "Flaticon",
      link: "/",
      icon: "m-menu__link-bullet m-menu__link-bullet--dot"
  }, {
      title: "Fontawesome",
      link: "/",
      icon: "m-menu__link-bullet m-menu__link-bullet--dot"
  }, {
      title: "Lineawesome",
      link: "/",
      icon: "m-menu__link-bullet m-menu__link-bullet--dot"
  }, {
      title: "Socicons",
      link: "/",
      icon: "m-menu__link-bullet m-menu__link-bullet--dot"
  }]
}, {
  title: "Buttons",
  link: "/",
  icon: "m-menu__link-icon flaticon-multimedia-1",
  children: [{
      title: "Button Base",
      link: "/",
      icon: "m-menu__link-bullet m-menu__link-bullet--dot",
      children: [{
          title: "Default Style",
          link: "/member/default-style",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }, {
          title: "Square Style",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }, {
          title: "Pill Style",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }, {
          title: "Air Style",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }]
  }, {
      title: "Button Group",
      link: "/",
      icon: "m-menu__link-bullet m-menu__link-bullet--dot"
  }, {
      title: "Button Dropdown",
      link: "/",
      icon: "m-menu__link-bullet m-menu__link-bullet--dot"
  }, {
      title: "Button Icon",
      link: "/",
      icon: "m-menu__link-bullet m-menu__link-bullet--dot",
      children: [{
          title: "Lineawesome Icons",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }, {
          title: "Fontawesome Icons",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }, {
          title: "Flaticon Icons",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }]
  }]
}, {
  title: "Forms",
  link: "/",
  icon: "m-menu__link-icon flaticon-interface-7",
  children: [{
      title: "Form Controls",
      link: "/",
      icon: "m-menu__link-bullet m-menu__link-bullet--dot",
      children: [{
          title: "Base Inputs",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }, {
          title: "Checkbox Radio",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }, {
          title: "Input Groups",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }, {
          title: "Switch",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }, {
          title: "Mega Options",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }]
  }, {
      title: "Form Widgets",
      link: "/",
      icon: "m-menu__link-bullet m-menu__link-bullet--dot",
      children: [{
          title: "Datepicker",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }, {
          title: "Datetimepicker",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }, {
          title: "Timepicker",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }, {
          title: "Daterangepicker",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }, {
          title: "Touchspin",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }, {
          title: "Maxlength",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }, {
          title: "Switch",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }, {
          title: "Multiple Select Splitter",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }, {
          title: "Bootstrap Select",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }, {
          title: "Select2",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }, {
          title: "Typeahead",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }, {
          title: "noUiSlider",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }, {
          title: "Form Repeater",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }, {
          title: "Ion Range Slider",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }, {
          title: "Input Masks",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }, {
          title: "Summernote WYSIWYG",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }, {
          title: "Markdown Editor",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }, {
          title: "Autosize",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }, {
          title: "Clipboard",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }, {
          title: "Dropzone",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }, {
          title: "Google reCaptcha",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }]
  }, {
      title: "Form Layouts",
      link: "/",
      icon: "m-menu__link-bullet m-menu__link-bullet--dot",
      children: [{
          title: "Default Forms",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }, {
          title: "Multi Column Forms",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }, {
          title: "Action Bars",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }]
  }, {
      title: "Form Validation",
      link: "/",
      icon: "m-menu__link-bullet m-menu__link-bullet--dot",
      children: [{
          title: "Validation States",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }, {
          title: "Form Controls",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }, {
          title: "Form Widgets",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }]
  }, {
      title: "Form Wizard",
      link: "/",
      icon: "m-menu__link-bullet m-menu__link-bullet--dot",
      children: [{
          title: "Form Wizard 1",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }, {
          title: "Form Wizard 2",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }, {
          title: "Form Wizard 3",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }, {
          title: "Form Wizard 4",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }, {
          title: "Form Wizard 5",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }]
  }]
}, {
  title: "Datatables",
  link: "/",
  icon: "m-menu__link-icon flaticon-tabs",
  children: [{
      title: "Base",
      link: "/",
      icon: "m-menu__link-bullet m-menu__link-bullet--dot",
      children: [{
          title: "Local Data",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }, {
          title: "JSON Data",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }, {
          title: "Ajax Data",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }, {
          title: "HTML Table",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }, {
          title: "Record Selection",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }, {
          title: "Local Sort",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }, {
          title: "Row Details",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }, {
          title: "Column Rendering",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }, {
          title: "Column Width",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }, {
          title: "Responsive Columns",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }, {
          title: "Translation",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }]
  }, {
      title: "Scrolling",
      link: "/",
      icon: "m-menu__link-bullet m-menu__link-bullet--dot",
      children: [{
          title: "Vertical Scrolling",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }, {
          title: "Horizontal Scrolling",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }, {
          title: "Both Scrolling",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }]
  }, {
      title: "Locked Columns",
      link: "/",
      icon: "m-menu__link-bullet m-menu__link-bullet--dot",
      children: [{
          title: "Left Locked Columns",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }, {
          title: "Right Locked Columns",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }, {
          title: "Both Locked Columns",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }, {
          title: "HTML Table",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }]
  }, {
      title: "Child Datatables",
      link: "/",
      icon: "m-menu__link-bullet m-menu__link-bullet--dot",
      children: [{
          title: "Local Data",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }, {
          title: "Remote Data",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }]
  }, {
      title: "API",
      link: "/",
      icon: "m-menu__link-bullet m-menu__link-bullet--dot",
      children: [{
          title: "API Methods",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }, {
          title: "Events",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }]
  }]
}, {
  title: "Portlets",
  link: "/",
  icon: "m-menu__link-icon flaticon-interface-1",
  children: [{
      title: "Base Portlets",
      link: "/",
      icon: "m-menu__link-bullet m-menu__link-bullet--dot"
  }, {
      title: "Advanced Portlets",
      link: "/",
      icon: "m-menu__link-bullet m-menu__link-bullet--dot"
  }, {
      title: "Creative Portlets",
      link: "/",
      icon: "m-menu__link-bullet m-menu__link-bullet--dot"
  }, {
      title: "Tabbed Portlets",
      link: "/",
      icon: "m-menu__link-bullet m-menu__link-bullet--dot"
  }, {
      title: "Draggable Portlets",
      link: "/",
      icon: "m-menu__link-bullet m-menu__link-bullet--dot"
  }, {
      title: "Portlet Tools",
      link: "/",
      icon: "m-menu__link-bullet m-menu__link-bullet--dot"
  }]
}, {
  title: "Timeline",
  link: "/",
  icon: "m-menu__link-icon flaticon-interface-6",
  children: [{
      title: "Timeline 1",
      link: "/",
      icon: "m-menu__link-bullet m-menu__link-bullet--dot"
  }, {
      title: "Timeline 2",
      link: "/",
      icon: "m-menu__link-bullet m-menu__link-bullet--dot"
  }]
}, {
  title: "Widgets",
  link: "/",
  icon: "m-menu__link-icon flaticon-network",
  children: [{
      title: "General Widgets",
      link: "/",
      icon: "m-menu__link-bullet m-menu__link-bullet--dot"
  }, {
      title: "Chart Widgets",
      link: "/",
      icon: "m-menu__link-bullet m-menu__link-bullet--dot"
  }]
}, {
  title: "Calendar",
  link: "/",
  icon: "m-menu__link-icon flaticon-calendar",
  children: [{
      title: "Basic Calendar",
      link: "/",
      icon: "m-menu__link-bullet m-menu__link-bullet--dot"
  }, {
      title: "List Views",
      link: "/",
      icon: "m-menu__link-bullet m-menu__link-bullet--dot"
  }, {
      title: "Google Calendar",
      link: "/",
      icon: "m-menu__link-bullet m-menu__link-bullet--dot"
  }, {
      title: "External Events",
      link: "/",
      icon: "m-menu__link-bullet m-menu__link-bullet--dot"
  }, {
      title: "Background Events",
      link: "/",
      icon: "m-menu__link-bullet m-menu__link-bullet--dot"
  }]
}, {
  title: "Charts",
  link: "/",
  icon: "m-menu__link-icon flaticon-diagram",
  children: [{
      title: "amCharts",
      link: "/",
      icon: "m-menu__link-bullet m-menu__link-bullet--dot",
      children: [{
          title: "amCharts Charts",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }, {
          title: "amCharts Stock Charts",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }, {
          title: "amCharts Maps",
          link: "/",
          icon: "m-menu__link-bullet m-menu__link-bullet--dot"
      }]
  }, {
      title: "Flot Charts",
      link: "/",
      icon: "m-menu__link-bullet m-menu__link-bullet--dot"
  }, {
      title: "Google Charts",
      link: "/",
      icon: "m-menu__link-bullet m-menu__link-bullet--dot"
  }, {
      title: "Morris Charts",
      link: "/",
      icon: "m-menu__link-bullet m-menu__link-bullet--dot"
  }]
}, {
  title: "Maps",
  link: "/",
  icon: "m-menu__link-icon flaticon-placeholder-1",
  children: [{
      title: "Google Maps",
      link: "/",
      icon: "m-menu__link-bullet m-menu__link-bullet--dot"
  }, {
      title: "JQVMap",
      link: "/",
      icon: "m-menu__link-bullet m-menu__link-bullet--dot"
  }]
}, {
  title: "Utils",
  link: "/",
  icon: "m-menu__link-icon flaticon-signs-2",
  children: [{
      title: "Session Timeout",
      link: "/",
      icon: "m-menu__link-bullet m-menu__link-bullet--dot"
  }, {
      title: "Idle Timer",
      link: "/",
      icon: "m-menu__link-bullet m-menu__link-bullet--dot"
  }]
}]