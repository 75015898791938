import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule} from '@angular/forms';

import { AdminRoutingModule } from './admin-routing.module';
import { UiModule } from '../ui/ui.module';

import { AdminComponent } from './admin.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { PaginationComponent } from './pagination/pagination.component';

// pages
import { UserListComponent } from './pages/user-list/user-list.component';
import { UserFormComponent } from './pages/user-form/user-form.component';

@NgModule({
  imports: [
    CommonModule,
    AdminRoutingModule,
    FormsModule,
    UiModule
  ],
  declarations: [
    AdminComponent,
    DashboardComponent, 
    PaginationComponent, 
    UserListComponent, 
    UserFormComponent
  ]
})
export class AdminModule { }
