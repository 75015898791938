import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

import { UserService }  from '../../../core/service/user.service';
import { UserModel, RolesModel } from '../../../core/models/user.model';
import { states, countries } from '../../../core/models/location';

@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.css']
})
export class UserFormComponent implements OnInit {

  @Input() user: UserModel;
  @Output() submitUser = new EventEmitter();

  private params: Subscription;
  private userRequest: Subscription;
  public submitUserSub: Subscription;

  formUser: UserModel;
  roles: RolesModel[];
  countries: any[] = countries;
  states: any[] = states;

  loading: boolean = true;
  isEdit: boolean = true;
  submitting: boolean = false;
  error: boolean = false;

  constructor(
    private route: ActivatedRoute, 
    private router: Router,
    private toastr: ToastrService,
    private api: UserService
  ) {}

  ngOnInit() {

    //this.formUser = new UserModel('','','',true,false,false,false,'ROLE_USER',0,'');

    this.params = this.route.params.subscribe((params: Params) => {
      this.userRequest = this.api
        .getUserById$(params.id)
        .subscribe(res => {
            this.user = res.user;
            this.formUser = new UserModel(
              this.user.first_name,
              this.user.last_name,
              '', // this.user.password
              this.user.identity,
              this.user.email,
              Boolean(Number(this.user.enabled)),
              this.user.token,
              this.user.address1,
              this.user.address2,
              this.user.city,
              this.user.state,
              this.user.country,
              this.user.zip,
              this.user.home_phone,
              this.user.mobile_phone,
              this.user.last_login,
              this.user.created,
              this.user.updated,
              this.user.id
            );
            this.roles = res.roles;
            this.loading = false;
        }, (err) => {
            this.loading = false;
            this.error = true;
            this.toastr.error(`Error authenticating: ${err.error}`);
        });

    });
  }

  ngOnDestroy() {
    if (this.params) {
      this.params.unsubscribe();
    }
    if (this.userRequest) {
      this.userRequest.unsubscribe();
    }
    if (this.submitUserSub) {
      this.submitUserSub.unsubscribe();
    }
  }

  onSubmit() {
    this.submitting = true;
    if (!this.isEdit) {
      this.submitUserSub = this.api
        .addUser$(this.formUser)
        .subscribe(
          data => this._handleSubmitSuccess(data),
          err => this._handleSubmitError(err)
        );
    } else {
      this.submitUserSub = this.api
        .editUser$(this.user.id, this.formUser)
        .subscribe(
          data => this._handleSubmitSuccess(data),
          err => this._handleSubmitError(err)
        );
    }
  }

  changeAccountEnabled() {
    this.formUser.enabled = !this.formUser.enabled;
  }

  private _handleSubmitSuccess(res) {
    const eventObj = {
      isEdit: this.isEdit,
      user: res
    };
    this.submitUser.emit(eventObj);
    this.error = false;
    this.submitting = false;
    this.toastr.success('User updated successfully.');
  }

  private _handleSubmitError(err) {
    const eventObj = {
      isEdit: this.isEdit,
      error: err
    };
    this.submitUser.emit(eventObj);
    this.submitting = false;
    this.error = true;
    this.toastr.error(`Error updating user: ${err.error}`);
  }

}

/**
 * template
 * [routerLink]="['/user', user.id, 'details']"
 * 
 * controller
 * router.navigate(['user', user.id, 'details']);
 * 
 * route
 * [path: 'user/:id', component:userComponent, pathMatch: 'full']
 * 
 * queryParams example
 * 
 * [routerLink]="['/admin/user/edit/id]" [queryParams]="{ order: 'popular'}
 *  this.sub = this.route.queryParams
 *   .filter(params => params.order)
 *   .subscribe((params: Params) => {
 *     console.log('params', params);
 *  });
 * 
 */
